import * as firebase from 'firebase';
import 'firebase/auth';
import 'firebase/firestore';
import * as firebaseConfigFile from './firebase-config.json';

// Initialize Firebase App
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfigFile.result.sdkConfig);
  firebase.auth().useDeviceLanguage();
}

export default firebase;

const serverTimestamp = () => {
  return firebase
    .firestore
    .FieldValue.serverTimestamp();
};

export const streamCollection = (collectionName, observer, filters, orderBy) => {

  let collectionRef = firebase.firestore().collection(collectionName);

  if (filters !== null) {
    filters.forEach(({ field, operator, value }) => {
      collectionRef = collectionRef.where(field, operator, value);
    });
  }

  if (orderBy) {
    collectionRef = collectionRef.orderBy(orderBy.field, orderBy.value);
  }

  return collectionRef.onSnapshot(observer);
};

export const getDoc = (collectionName, itemId) => {
  return firebase.firestore()
    .collection(collectionName)
    .doc(itemId)
    .get();
};

export const getDocRef = (collectionName, itemId) => {
  return firebase.firestore()
    .collection(collectionName)
    .doc(itemId);
};

export const updateDoc = (collectionName, itemId, values) => {
  return firebase.firestore()
    .collection(collectionName)
    .doc(itemId)
    .update({ ...values, updated_on: serverTimestamp(), });
};

export const getCollection = (collectionName, filters, orderBy) => {

  let collectionRef = firebase.firestore().collection(collectionName);

  if (filters !== null) {
    filters.forEach(({ field, operator, value }) => {
      collectionRef = collectionRef.where(field, operator, value);
    });
  }
  if (orderBy) {
    collectionRef = collectionRef.orderBy(orderBy.field, orderBy.value);
  }

  return collectionRef.get();
};

export const addDoc = (collectionName, item) => {
  return firebase.firestore()
    .collection(collectionName)
    .add({ ...item, created_at: serverTimestamp(), });
};

export const serverDate = () => {
  return firebase.firestore.Timestamp.now().toDate()
};