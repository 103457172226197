// in src/posts.js
import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Show,
  Edit,
  Filter,
  BooleanInput,
  required,
  TextField,
  TextInput,
  ShowButton,
  EditButton,
  ReferenceArrayField,
  SingleFieldList,
  SelectInput,
  Create,
  NumberInput,
  TabbedForm,
  BooleanField,
  TabbedShowLayout,
  DateField,
  ImageInput,
  ImageField,
  ChipField,
  SelectArrayInput,
  ReferenceArrayInput,
  Tab,
  FormTab,
  SelectField,
} from "react-admin";
import { ColorField, ColorInput } from 'react-admin-color-input';

const breaksList = [
  { id: 'none', name: 'Sin descanso' },
  { id: '7:00', name: '7:00' },
  { id: '7:30', name: '7:30' },
  { id: '8:00', name: '8:00 ' },
  { id: '8:30', name: '8:30' },
  { id: '9:00', name: '9:00' },
  { id: '9:30', name: '9:30' },
  { id: '10:00', name: '10:00' },
  { id: '10:30', name: '10:30' },
  { id: '11:00', name: '11:00' },
  { id: '11:30', name: '11:30' },
  { id: '12:00', name: '12:00' },
  { id: '12:30', name: '12:30' },
  { id: '13:00', name: '13:00' },
  { id: '13:30', name: '13:30' },
  { id: '14:00', name: '14:00' },
  { id: '14:30', name: '14:30' },
  { id: '15:00', name: '15:00' },
  { id: '15:30', name: '15:30' },
  { id: '16:00', name: '16:00' },
  { id: '16:30', name: '16:30' },
  { id: '17:00', name: '17:00' },
  { id: '17:30', name: '17:30' },
  { id: '18:00', name: '18:00' },
  { id: '18:30', name: '18:30' },
  { id: '19:00', name: '19:00' },
  { id: '19:30', name: '19:30' },
  { id: '20:00', name: '20:00' },
  { id: '20:30', name: '20:30' },
  { id: '21:00', name: '21:00' },
  { id: '21:30', name: '21:30' },
  { id: '22:00', name: '22:00' },
];

const HourSelector = ({ source, label, validate }) => <SelectInput
  source={source}
  label={label}
  translateChoice={false}
  validate={validate}
  options={{
    autoWidth: true,
  }}
  choices={breaksList} />;



const PostFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ID" source="id" />
  </Filter>
);

const list = (props) => (
  <List {...props} filters={<PostFilter />} title="Listado de trabajadores" sort={{ field: 'created_at', order: 'DESC' }}>
    <Datagrid>
      <TextField source="name" label="Nombre" />
      <TextField source="order" label="Orden" />
      <BooleanField source="public" label="Publicado" />

      <DateField source="updated_on" label="última actualización" showTime locales="es-ES" />

      <ShowButton label="" />
      <EditButton label="" />
    </Datagrid>
  </List>
);

const show = (props) => (
  <Show {...props} title="Ver datos del trabajador" >
    <TabbedShowLayout>

      <Tab label="Trabajador">
        <TextField source="id" />
        <TextField source="name" label="Nombre" />
        <TextField source="description" label="Descripción" />
        <TextField source="order" label="Orden" />

        <ImageField source="avatar.src" label="Imagen de perfil" />

        <ReferenceArrayField label="Servicios" perPage="500" reference="facilities/aeMBRl79T9NdzU6YkH87/services" source="services">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>

        <BooleanField source="public" label="Publicado" />

        <DateField source="created_at" label="Fecha de creación" showTime locales="es-ES" />
        <DateField source="updated_on" label="última actualización" showTime locales="es-ES" />
      </Tab>

      <Tab label="Reservas">
        <SelectField source="break_time.start" choices={breaksList} label="Descanso (Comienzo)" />
        <SelectField source="break_time.end" choices={breaksList} label="Descanso (Fin)" />
        <TextField source="service_break_minutes" label="Descanso entre reservas (Minutos)" />
        <ColorField source="color" />
      </Tab>

    </TabbedShowLayout>
  </Show>
);

const edit = (props) => (
  <Edit {...props} title="Editar datos del trabajador" undoable={false}>
    <TabbedForm>

      <FormTab label="Trabajador">
        <TextInput source="id" options={{ disabled: true }} />
        <TextInput source="name" label="Nombre" validate={[required()]} />
        <TextInput source="description" multiline label="Descripción" />
        <NumberInput source="order" label="Orden en los listados" validate={[required()]} />

        <ImageInput source="avatar" label="Imagen de perfil" accept="image/*" validate={[required()]} maxSize={500000}>
          <ImageField source="src" title="title" />
        </ImageInput>

        <ReferenceArrayInput label="Servicios" perPage="500" source="services" allowEmpty reference="facilities/aeMBRl79T9NdzU6YkH87/services">
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>

        <ImageInput source="pictures" multiple label="Imágenes de trabajos" accept="image/*" maxSize={2000000}>
          <ImageField source="src" title="title" />
        </ImageInput>

        <BooleanInput source="public" label="Publicado" validate={[required()]} />

        <DateField source="created_at" label="Fecha de creación" showTime locales="es-ES" />
        <DateField source="updated_on" label="última actualización" showTime locales="es-ES" />
      </FormTab>

      <FormTab label="Reservas">
        <NumberInput source="service_break_minutes" label="Descanso entre reservas (Minutos)" validate={[required()]} />
        <HourSelector source="break_time.start" label="Descanso (Comienzo)" validate={[required()]} />
        <HourSelector source="break_time.end" label="Descanso (Fin)" validate={[required()]} />
        <ColorInput source="color" picker="Sketch" validate={[required()]} />
      </FormTab>

    </TabbedForm>
  </Edit>
);

const create = (props) => (
  <Create {...props} title="Crear un trabajador">
    <TabbedForm>

      <FormTab label="Trabajador">
        <TextInput source="name" label="Nombre" validate={[required()]} />
        <TextInput source="description" multiline label="Descripción" />
        <NumberInput source="order" label="Orden en los listados" validate={[required()]} />

        <ImageInput source="avatar" label="Imagen de perfil" accept="image/*" validate={[required()]} maxSize={500000}>
          <ImageField source="src" title="title" />
        </ImageInput>

        <ReferenceArrayInput label="Servicios" perPage="500" source="services" allowEmpty reference="facilities/aeMBRl79T9NdzU6YkH87/services">
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>

        <ImageInput source="pictures" multiple label="Imágenes de trabajos" accept="image/*" maxSize={2000000} >
          <ImageField source="src" title="title" />
        </ImageInput>

        <BooleanInput source="public" label="Publicado" validate={[required()]} />

      </FormTab>

      <FormTab label="Reservas">
        <NumberInput source="service_break_minutes" label="Descanso entre reservas (Minutos)" validate={[required()]} />
        <HourSelector source="break_time.start" label="Descanso (Comienzo)" validate={[required()]} />
        <HourSelector source="break_time.end" label="Descanso (Fin)" validate={[required()]} />
        <ColorInput source="color" picker="Sketch" validate={[required()]} />
      </FormTab>

    </TabbedForm>
  </Create>
);

export default {
  list: list,
  show: show,
  edit: edit,
  create: create
};
