// in src/posts.js
import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Show,
  Edit,
  Filter,
  BooleanInput,
  required,
  TextField,
  TextInput,
  ShowButton,
  EditButton,
  DeleteButton,
  DateInput,
  Create,
  SimpleForm,
  SimpleShowLayout,
  DateField,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
} from "react-admin";

const Filters = (props) => (
  <Filter {...props}>
      <TextInput source="id" label="ID" />
      <TextInput source="name" label="Nombre" />
      <TextInput source="description" label="Descripción" />

      <DateInput source="start" label="Inicio"  locales="es-ES" />
      <DateInput source="end" label="Fin" locales="es-ES" />
        </Filter>
);

const list = (props) => (
  <List {...props} filters={<Filters />} title="Listado de vacaciones" sort={{ field: 'created_at', order: 'DESC' }}>
    <Datagrid>
      <TextField source="name" label="Nombre" />
      <DateField source="start" label="Inicio" locales="es-ES"/>
      <DateField source="end" label="Fin" locales="es-ES" />

      <ReferenceArrayField label="Trabajadores" reference="facilities/aeMBRl79T9NdzU6YkH87/workers" source="workers">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>

      <DateField source="updated_on" label="última actualización" showTime locales="es-ES"/>
      <DateField source="created_at" label="Fecha de creación" showTime locales="es-ES" />

      <ShowButton label="" />
      <EditButton label="" />
      <DeleteButton label="" redirect={false} />
    </Datagrid>
  </List>
);

const show = (props) => (
  <Show {...props} title="Ver datos del evento" >
    <SimpleShowLayout>

      <TextField source="id" />
      <TextField source="name" label="Nombre" />
      <DateField source="start" label="Inicio" locales="es-ES" />
      <DateField source="end" label="Fin" locales="es-ES" />
      <TextField source="description" label="Descripción" />

      <ReferenceArrayField label="Trabajadores" reference="facilities/aeMBRl79T9NdzU6YkH87/workers" source="workers">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>

      <DateField source="created_at" label="Fecha de creación" showTime locales="es-ES" />
      <DateField source="updated_on" label="última actualización" showTime locales="es-ES" />

    </SimpleShowLayout>
  </Show>
);

const edit = (props) => (
  <Edit {...props} title="Editar datos del evento" undoable={false}>
    <SimpleForm>
      <TextInput source="id" options={{ disabled: true }} />
      <TextInput source="name" label="Nombre" validate={[required()]} />
      <TextInput source="description" label="Descripción" />

      <DateInput source="start" label="Inicio" validate={[required()]} locales="es-ES" />
      <DateInput source="end" label="Fin" validate={[required()]} locales="es-ES" />

      <ReferenceArrayInput label="Trabajadores" source="workers" allowEmpty reference="facilities/aeMBRl79T9NdzU6YkH87/workers">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>

      <DateField source="created_at" label="Fecha de creación" showTime locales="es-ES"  />
      <DateField source="updated_on" label="última actualización" showTime locales="es-ES" />

    </SimpleForm>
  </Edit>
);

const create = (props) => (
  <Create {...props} title="Crear un evento">
    <SimpleForm>
      <TextInput source="name" label="Nombre" validate={[required()]} />
      <TextInput source="description" label="Descripción"  />

      <DateInput source="start" label="Inicio" validate={[required()]} locales="es-ES"  />
      <DateInput source="end" label="Fin" validate={[required()]} locales="es-ES"  />

      <ReferenceArrayInput label="Trabajadores" source="workers" allowEmpty reference="facilities/aeMBRl79T9NdzU6YkH87/workers">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      
    </SimpleForm>
  </Create>
);

export default {
  list: list,
  show: show,
  edit: edit,
  create: create
};
