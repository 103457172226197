// in src/posts.js
import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Show,
  Edit,
  Filter,
  BooleanInput,
  required,
  TextField,
  TextInput,
  ShowButton,
  EditButton,
  Create,
  NumberInput,
  SimpleForm,
  BooleanField,
  SimpleShowLayout,
  DateField,
  SelectArrayInput,
  TabbedForm,
  FormTab,
  TabbedShowLayout,
  Tab
} from "react-admin";

const Filters = (props) => (
  <Filter {...props}>
    <TextInput source="id" label="ID" />
    <TextInput source="name" label="Nombre" />
    <TextInput source="description" label="Descripción" />
    <NumberInput source="duration" label="Duración" />
    <NumberInput source="price" label="Precio" />
  </Filter>
);

const list = (props) => (
  <List {...props} filters={<Filters />} title="Listado de servicios" sort={{ field: 'created_at', order: 'DESC' }}>
    <Datagrid>
      <TextField source="name" label="Nombre" />
      <TextField source="duration" label="Duración" />
      <TextField source="price" label="Precio" />
      <TextField source="order" label="Orden" />
      <BooleanField source="public" label="Publicado" />

      <DateField source="created_at" label="Fecha de creación" showTime locales="es-ES" />
      <DateField source="updated_on" label="última actualización" showTime locales="es-ES" />

      <ShowButton label="" />
      <EditButton label="" />
    </Datagrid>
  </List>
);

const show = (props) => (
  <Show {...props} title="Ver datos del servicio" >
    <TabbedShowLayout>

      <Tab label="Resumen">
        <TextField source="id" />
        <TextField source="name" label="Nombre" />
        <TextField source="description" label="Descripción" />
        <TextField source="duration" label="Duración" />
        <TextField source="price" label="Precio" />

        <TextField source="order" label="Orden" />
        <BooleanField source="public" label="Publicado" />

        <DateField source="created_at" label="Fecha de creación" showTime locales="es-ES" />
        <DateField source="updated_on" label="última actualización" showTime locales="es-ES" />
      </Tab>

      <Tab label="Duración">
        <TextField source="duration" label="Duración total (minutos)" />
        <TextField source="bookable_preparation_minutes" label="Preparación (minutos)" />
        <TextField source="bookable_minutes" label="Tiempo reservable  (minutos)" />
        <BooleanField source="bookable_enabled" label="Reservable" />
      </Tab>

      <Tab label="Traducciones">
        <TextField source="name_translations.es" label="Nombre (Castellano)" />
        <TextField source="name_translations.en" label="Nombre (Inglés)" />
        <TextField source="name_translations.ca" label="Nombre (Catalán)" />
        <TextField source="name_translations.fr" label="Nombre (Francés)" />
        <TextField source="name_translations.ro" label="Nombre (Rumano)" />

        <hr />
        <TextField source="description_translations.es" label="Descripción (Castellano)" />
        <TextField source="description_translations.en" label="Descripción (Inglés)" />
        <TextField source="description_translations.ca" label="Descripción (Catalán)" />
        <TextField source="description_translations.fr" label="Descripción (Francés)" />
        <TextField source="description_translations.ro" label="Descripción (Rumano)" />
      </Tab>

    </TabbedShowLayout>
  </Show>
);

const edit = (props) => (
  <Edit {...props} title="Editar datos del servicio" undoable={false}>
    <TabbedForm>
      <FormTab label="Resumen">
        <TextInput source="id" options={{ disabled: true }} />
        <TextInput source="name" label="Nombre" validate={[required()]} />
        <TextInput source="description" label="Descripción" />

        <NumberInput source="price" label="Precio" validate={[required()]} />

        <NumberInput source="order" label="Orden en los listados" validate={[required()]} />

        <BooleanInput source="public" label="Publicado" validate={[required()]} />

        <DateField source="created_at" label="Fecha de creación" showTime locales="es-ES" />
        <DateField source="updated_on" label="última actualización" showTime locales="es-ES" />

      </FormTab>

      <FormTab label="Duración">
        <NumberInput source="duration" label="Duración total (minutos)" validate={[required()]} />
        <NumberInput source="bookable_preparation_minutes" label="Preparación (minutos)" />
        <NumberInput source="bookable_minutes" label="Tiempo reservable (minutos)" />
        <BooleanInput source="bookable_enabled" label="Reservable" validate={[required()]} />
      </FormTab>

      <FormTab label="Traducciones">
        <TextInput source="name_translations.es" label="Nombre (Castellano)" />
        <TextInput source="name_translations.en" label="Nombre (Inglés)" />
        <TextInput source="name_translations.ca" label="Nombre (Catalán)" />
        <TextInput source="name_translations.fr" label="Nombre (Francés)" />
        <TextInput source="name_translations.ro" label="Nombre (Rumano)" />
        <hr />
        <TextInput multiline source="description_translations.es" label="Descripción (Castellano)" />
        <TextInput multiline source="description_translations.en" label="Descripción (Inglés)" />
        <TextInput multiline source="description_translations.ca" label="Descripción (Catalán)" />
        <TextInput multiline source="description_translations.fr" label="Descripción (Francés)" />
        <TextInput multiline source="description_translations.ro" label="Descripción (Rumano)" />
      </FormTab>

    </TabbedForm>
  </Edit>
);

const create = (props) => (
  <Create {...props} title="Crear un servicio">
    <TabbedForm>

      <FormTab label="Resumen">
        <TextInput source="name" label="Nombre" validate={[required()]} />
        <TextInput source="description" multiline label="Descripción" />

        <NumberInput source="price" label="Precio" validate={[required()]} />

        <NumberInput source="order" label="Orden en los listados" validate={[required()]} />

        <BooleanInput source="public" label="Publicado" validate={[required()]} />
      </FormTab>

      <FormTab label="Duración">
        <NumberInput source="duration" label="Duración total (minutos)" validate={[required()]} />
        <NumberInput source="bookable_preparation_minutes" label="Preparación (minutos)" />
        <NumberInput source="bookable_minutes" label="Tiempo reservable (minutos)" />
        <BooleanInput source="bookable_enabled" label="Reservable" defaultValue={false} validate={[required()]} />
      </FormTab>

      <FormTab label="Traducciones">
        <TextInput source="name_translations.es" label="Nombre (Castellano)" />
        <TextInput source="name_translations.en" label="Nombre (Inglés)" />
        <TextInput source="name_translations.ca" label="Nombre (Catalán)" />
        <TextInput source="name_translations.fr" label="Nombre (Francés)" />
        <TextInput source="name_translations.ro" label="Nombre (Rumano)" />
        <hr />
        <TextInput multiline source="description_translations.es" label="Descripción (Castellano)" />
        <TextInput multiline source="description_translations.en" label="Descripción (Inglés)" />
        <TextInput multiline source="description_translations.ca" label="Descripción (Catalán)" />
        <TextInput multiline source="description_translations.fr" label="Descripción (Francés)" />
        <TextInput multiline source="description_translations.ro" label="Descripción (Rumano)" />
      </FormTab>

    </TabbedForm>
  </Create>
);

export default {
  list: list,
  show: show,
  edit: edit,
  create: create
};
