import { useState } from 'react';
import { streamCollection } from '../firebase';
import useDeepCompareEffect from 'use-deep-compare-effect'

export const useCollectionStream = (collectionName, filters, orderBy) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLError, setIsError] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  useDeepCompareEffect(() => {
    setIsLoading(true);
    const unsubscribe = streamCollection(collectionName, {
      next: querySnapshot => {
        setIsLoading(true);
        let data = [];
          if (querySnapshot.size > 0) {
            querySnapshot.forEach(function(doc) {
              data.push({...doc.data(), id: doc.id});
            });
          }
          setData(data);
          setIsLoading(false);
      },
      error: (error) => {
        setIsLoading(true);
        console.log("Error getting collection:", error);
        setIsError(true);
        setError(error);
        setData([]);
        setIsLoading(false);
      }
  }, filters, orderBy);
  return unsubscribe;

  }, [collectionName, filters, orderBy]);

  return {isLoading, isLError, data, error};
}