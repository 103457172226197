import React, { useState } from "react";
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment'
import "moment/locale/es";
import { useForm, Controller } from "react-hook-form";

import useDeepCompareEffect from 'use-deep-compare-effect'
import { Modal } from 'react-responsive-modal';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useCollectionStream } from "../hooks/useCollectionsStream";
import { useDoc } from "../hooks/useDoc";
import { useCollectionPrimitive } from "../hooks/useCollectionPrimitive";
import { serverDate, addDoc } from "../firebase"

require('react-big-calendar/lib/css/react-big-calendar.css');
require('react-responsive-modal/styles.css');

// set locale``
moment.locale('es');

function Event({ event }) {
  return (
    <span>
      <strong>{event.title}</strong>
      {event.desc && ':  ' + event.desc}
    </span>
  )
}
const customEventPropGetter = (event, start, end, isSelected) => {

  if (event.color)
    return {
      className: 'special-day',
      style: {
        backgroundColor: event.color,
        color: "black",
        border: '0.2',
      },
    }
  else return {}
}

const bookingStatusCorrect = "correct";
const localizer = momentLocalizer(moment);

const messages = {
  allDay: 'todo el dia',
  previous: 'anterior',
  next: 'siguiente',
  today: 'hoy',
  month: 'mes',
  week: 'semana',
  day: 'día',
  agenda: 'Agenda',
  date: 'fecha',
  time: 'hora',
  event: 'evento', // Or anything you want
  //showMore: total => `+ ${total} événement(s) supplémentaire(s)`
}

const FormateMinutes = (value) => {
  return moment.duration(value, "minutes").humanize();
};

const CalendarView = () => {
  const serverDateTime = moment(serverDate()).startOf('minute');

  const [selectedView, setSelectedView] = useState(Views.DAY);
  const [selectedDate, setSelectedDate] = useState(serverDateTime.toDate());
  const [eventsList, setEventsList] = useState([]);
  const [showEventModal, setShowEventModal] = useState(false);
  const [addEventModal, setAddEventModal] = useState(false);
  const { control, handleSubmit, errors } = useForm();
  const [workersList, setWorkersList] = useState([]);
  const [servicesList, setServicesList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const selectedDateAsMoment = moment(selectedDate);
  const facility_id = 'aeMBRl79T9NdzU6YkH87';

  const facility_data = useDoc("facilities/" + facility_id + "/facility_data", facility_id);

  const filters = [
    { field: 'facility_id', operator: '==', value: facility_id },
    { field: 'start_datetime', operator: '>', value: selectedDateAsMoment.startOf(selectedView).toDate() },
    { field: 'start_datetime', operator: '<', value: selectedDateAsMoment.endOf(selectedView).toDate() }
  ];
  const orderBy = { field: "start_datetime", filters: "asc" };
  const bookings = useCollectionStream("bookings", filters, orderBy);
  // TODO get holidays for the selected date 
  const holidays = useCollectionPrimitive('facilities/' + facility_id + '/holidays');
  const workers = useCollectionPrimitive('facilities/' + facility_id + '/workers');
  const services = useCollectionPrimitive('facilities/' + facility_id + '/services');

  useDeepCompareEffect(() => {

    if (!facility_data.isLoading && !bookings.isLoading && !holidays.isLoading) {
      const formatListOfBookings = [];

      bookings.data.forEach((booking) => {
        if (booking.deleted !== true) {
          let workerName = '', workerColor = '', serviceName = '';

          if (booking.service_data !== null) {
            if (booking.service_data.name !== null) {
              serviceName = `(${booking.service_data.name} - ${FormateMinutes(booking.service_data.duration)})`;
            } else {
              serviceName = `(${FormateMinutes(booking.service_data.duration)})`;
            }
          }

          if (booking.worker_data !== null) {
            workerColor = booking.worker_data.color;
            workerName = `con ${booking.worker_data.name}`;
          }

          const color = booking.worker_data ? booking.worker_data.color : '';
          formatListOfBookings.push({
            start: booking.start_datetime.toDate(),
            end: booking.end_with_break_datatime.toDate(),
            title: `${booking.name} ${serviceName} ${workerName}`,
            desc: booking.comment ?? '',
            color: booking.status === bookingStatusCorrect ? color : "#f8f8ff",
            urlShow: '#/bookings/' + booking.id + '/show',
            urlEdit: '#/bookings/' + booking.id,
            startFormatted: moment(booking.start_datetime.toDate()).format("H:mm - dddd, D/M/YYYY"),
            endFormatted: moment(booking.end_with_break_datatime.toDate()).format("H:mm - dddd, D/M/YYYY"),
            //resourceId: booking.worker_id,
          });
        }

      });

      const formatListOfHolidays = [];

      holidays.data.forEach((holiday) => {
        if (holiday.deleted !== true) {
          formatListOfHolidays.push({
            start: holiday.start,
            end: holiday.end,
            title: holiday.name,
            desc: holiday.description ?? '',
            startFormatted: moment(holiday.start).format("dddd, D/M/YYYY"),
            endFormatted: moment(holiday.end).format("dddd, D/M/YYYY"),
            urlShow: '#/facilities/aeMBRl79T9NdzU6YkH87/holidays/' + holiday.id + '/show',
            urlEdit: '#/facilities/aeMBRl79T9NdzU6YkH87/holidays/' + holiday.id,
            allDay: true,
          });
        }
      });
      setEventsList(formatListOfBookings.concat(formatListOfHolidays))
    }
  }, [holidays, bookings, facility_data]);

  useDeepCompareEffect(() => {
    if (!workers.isLoading) {
      setWorkersList(workers.data)
    }
  }, [workers]);

  useDeepCompareEffect(() => {
    if (!services.isLoading) {
      setServicesList(services.data)
    }
  }, [services]);

  if (bookings.isLoading || holidays.isLoading || facility_data.isLoading || workers.isLoading || isLoading) {
    return (
      <Box m="auto">
        <CircularProgress color="#fff" />
      </Box>
    );
  }

  // TODO save error and show a generic error
  if (bookings.isLError || holidays.isLError || facility_data.isLError || workers.isLError) {
    return (
      <div>
        bookings: {JSON.stringify(bookings.error)}
        holidays: {JSON.stringify(holidays.error)}
        facility_data: {JSON.stringify(facility_data.error)}
        workers: {JSON.stringify(workers.error)}
      </div>
    );
  }

  // const customDayPropGetter = date => {
  //   console.log(date);
  //   const dateAsMoment = moment(date)
  //   // facility schedule 
  //   const schedule = facility_data.data.schedule;
  //   const workingHours = schedule['day' + dateAsMoment.isoWeekday()];
  //   const isClosed = workingHours.closed ?? false;
  //   if (isClosed) {
  //     return {
  //       className: 'special-day',
  //       style: {
  //         border: 'solid 3px  red ',
  //       },
  //     }
  //   } else return {}
  // }

  const onCloseShowEventModal = () => setShowEventModal(false);
  const onCloseAddEventModal = () => setAddEventModal(false);

  const saveEvent = ({ name, comment, phone, worker, service }) => {

    setIsLoading(true);
    const workerData = worker !== 'none' ? {
      id: worker.id,
      name: worker.name,
      avatar: worker.avatar,
      color: worker.color,
      service_break_minutes: worker.service_break_minutes,
      break_time: worker.break_time
    } : null;

    const serviceData = service !== 'none' ? {
      id: service.id,
      name: service.name,
      price: service.price,
      duration: service.duration
    } : {
      id: null,
      name: null,
      price: null,
      duration: moment(addEventModal.end).diff(moment(addEventModal.start), 'minutes')
    };

    addDoc("bookings", {
      name: name,
      comment: comment,
      phone: phone,

      status: bookingStatusCorrect,
      status_admin: bookingStatusCorrect,
      notification_sent: false,
      wait_list_sent: false,
      notification_day_before_sent: false,

      worker_id: worker !== 'none' ? worker.id : null,
      service_id: service !== 'none' ? service.id : null,
      facility_id: facility_id,

      start_datetime: addEventModal.start,
      end_datetime: addEventModal.end,
      end_with_break_datatime: addEventModal.end,

      worker_data: workerData,
      service_data: serviceData,

      // TODO get admin id
      user_id: null,
    })
      .then(function () {
        onCloseAddEventModal()
        setIsLoading(false);
      })
      .catch(function (error) {
        // TODO show error
        console.log("Error on saving event:", error);
        setIsLoading(false);
      });
  }

  return <div>
    <Calendar
      messages={messages}
      popup={true}
      scrollToTime={serverDateTime.toDate()}
      localizer={localizer}
      events={eventsList}
      //startAccessor="start"
      //endAccessor="end"
      // TODO get height from windows
      style={{ height: 480, margin: 10 }}
      defaultView={selectedView}
      views={[Views.DAY, Views.WEEK, Views.MONTH]}
      onSelectEvent={event => {
        setShowEventModal(event);
      }}
      defaultDate={serverDateTime.toDate()}
      // TODO show the closed days in the calendar 
      //dayPropGetter={customDayPropGetter}
      eventPropGetter={customEventPropGetter}
      components={{
        event: Event,
      }}
      //getNow={() => {moment(serverDate()).toDate()}}

      view={selectedView}
      date={selectedDate}

      // it needs the date={selectedDate}
      onNavigate={(data) => { setSelectedDate(data) }}
      onView={setSelectedView}

      // show multiple calendar in the same page
      // resources={workersList}
      // resourceIdAccessor="id"
      // resourceTitleAccessor="name"


      step={15}
      //timeslots={8}
      selectable={true}
      onSelectSlot={(event) => setAddEventModal({
        ...event,
        startFormatted: moment(event.start).format("H:mm - dddd, D/M/YYYY"),
        endFormatted: moment(event.end).format("H:mm - dddd, D/M/YYYY"),
      })}
    />
    <Modal open={showEventModal ? true : false} onClose={onCloseShowEventModal} center>
      {(showEventModal !== false) ? <div>
        <h2>{showEventModal.title}</h2>
        <p> Inicio: {showEventModal.startFormatted}</p>
        <p> Fin: {showEventModal.endFormatted}</p>
        <p> {showEventModal.desc ?? null}</p>
        <Button variant="contained" color="primary" href={showEventModal.urlShow} style={{ marginLeft: 10 }}>
          Ver evento
        </Button>

        <Button variant="contained" color="secondary" href={showEventModal.urlEdit} style={{ marginLeft: 10 }}>
          Editar evento
        </Button>
      </div> : null}
    </Modal>


    <Modal open={addEventModal ? true : false} onClose={onCloseAddEventModal} center>
      {(addEventModal !== false) ? <Box component="div" m={1} >

        <Controller
          control={control}
          name="name"
          rules={{ required: true, minLength: 2 }}
          defaultValue={''}
          render={({ onChange, value }) => (
            <TextField
              label="Nombre"
              onChange={value => onChange(value)}
              value={value}
              error={errors.name ? true : false}
              autoFocus={true}
            />
          )}
        />

        <br />
        <br />
        <Controller
          control={control}
          name="phone"
          rules={{ required: false, minLength: 6 }}
          defaultValue={''}
          render={({ onChange, value }) => (
            <TextField
              label="Teléfono"
              onChange={value => onChange(value)}
              value={value}
              error={errors.phone ? true : false}
              autoFocus={true}
            />
          )}
        />

        <br />
        <br />
        <Controller
          control={control}
          name="comment"
          rules={{ required: false }}
          defaultValue={''}
          render={({ onChange, value }) => (
            <TextField
              label="Comentario"
              onChange={value => onChange(value)}
              value={value}
            />
          )}
        />
        <br />
        <br />
        <Controller
          control={control}
          name="worker"
          rules={{ required: true, minLength: 2 }}
          defaultValue={'none'}
          render={({ onChange, value }) => (
            <TextField
              onChange={value => onChange(value)}
              value={value}
              error={errors.worker ? true : false}
              label="trabajador"
              select>
              <MenuItem key="none" value="none" >Ninguno</MenuItem>
              {workersList.map((worker) => {
                return <MenuItem key={worker.id} value={worker}>{worker.name}</MenuItem>
              })}
            </TextField>
          )}
        />
        <br />
        <br />
        <Controller
          control={control}
          name="service"
          rules={{ required: true, minLength: 2 }}
          defaultValue={'none'}
          render={({ onChange, value }) => (
            <TextField
              onChange={value => onChange(value)}
              value={value}
              error={errors.service ? true : false}
              label="Servicio"
              select>
              <MenuItem key="none" value="none" >Ninguno</MenuItem>
              {servicesList.map((worker) => {
                return <MenuItem key={worker.id} value={worker}>{worker.name}</MenuItem>
              })}
            </TextField>
          )}
        />
        <br />
        <br />
        <TextField disabled id="standard-disabled" label="Inicio" defaultValue={addEventModal.startFormatted} />
        <br />
        <br />
        <TextField disabled id="standard-disabled" label="Fin" defaultValue={addEventModal.endFormatted} />
        <br />
        <br />
        <br />
        <Button variant="contained" color="primary" onClick={handleSubmit(saveEvent)} style={{ marginLeft: 10 }}>
          Confirmar
        </Button>
      </Box> : null}
    </Modal>
  </div>;
};

export default CalendarView;