import * as React from "react";
import { Admin, Resource } from "react-admin";
import {
  FirebaseDataProvider,
  FirebaseAuthProvider
} from "react-admin-firebase";
import spanishMessages from '@blackbox-vision/ra-language-spanish';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import './App.css';

import CustomLoginPage from './screens/CustomLoginPage';
import Users from "./screens/users";
import Facility from "./screens/facilities";
import Workers from "./screens/workers";
import Services from "./screens/services";
import Holidays from "./screens/holidays";
import Images from "./screens/images";
import Bookings from "./screens/bookings";
import Dashboard from "./screens/calendar";
import Campaigns from "./screens/campaigns";
import ExtraServices from "./screens/extraServices";
import firebaseAppInstance from "./firebase"

import UserIcon from '@material-ui/icons/People';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import FreeBreakfastIcon from '@material-ui/icons/FreeBreakfast';
import WorkIcon from '@material-ui/icons/Work';
import EventIcon from '@material-ui/icons/Event';
import EventSeatIcon from '@material-ui/icons/EventSeat';
import AddShoppingCart from '@material-ui/icons/AddShoppingCart';
import SendIcon from '@material-ui/icons/Send';

const options = {
  // Your own, previously initialized firebase app instance
  app: firebaseAppInstance,

  // Enable logging of react-admin-firebase
  logging: true,
  // Have custom metadata field names instead of: 'createdate', 'lastupdate', 'createdby', 'updatedby'
  renameMetaFields: {
    created_at: 'created_at', // default: 'createdate'
    created_by: 'created_by', // default: 'createdby'
    updated_at: 'updated_on', // default: 'lastupdate'
    updated_by: 'updated_by', // default: 'updatedby'
  },
  // Adds 'deleted' meta field for non-destructive deleting functionality
  // NOTE: Hides 'deleted' records from list views unless overridden by filtering for {deleted: true} 
  softDelete: true,
  // Changes meta fields like 'createdby' and 'updatedby' to store user IDs instead of email addresses
  associateUsersById: true,
  // Add file name to storage path, when set to true the file name is included in the path
  useFileNamesInStorage: false,
  // Use firebase sdk queries for pagination, filtering and sorting
  lazyLoading: {
    enabled: false
  },
  // Logging of all reads performed by app (additional feature, for lazy-loading testing)
  firestoreCostsLogger: {
    enabled: false,
  },
  // Authentication persistence, defaults to 'session', options are 'session' | 'local' | 'none'
  persistence: 'local',
}
const dataProvider = FirebaseDataProvider({}, options);
const authProvider = FirebaseAuthProvider({}, options);
const i18nProvider = polyglotI18nProvider(() => spanishMessages, 'es');

class App extends React.Component {
  render() {
    return (
      <Admin
        loginPage={CustomLoginPage}
        dataProvider={dataProvider}
        authProvider={authProvider}
        i18nProvider={i18nProvider}
        dashboard={Dashboard}
      >
        <Resource
          options={{ label: 'Reservas' }}
          name="bookings"
          {...Bookings}
          icon={EventIcon}
        />
        <Resource
          options={{ label: 'Clientes' }}
          name="users_data"
          icon={UserIcon}
          {...Users}
        />
        <Resource
          options={{ label: 'Campañas' }}
          name="facilities/aeMBRl79T9NdzU6YkH87/campaigns"
          icon={SendIcon}
          {...Campaigns}
        />
        <Resource
          options={{ label: 'Servicios' }}
          name="facilities/aeMBRl79T9NdzU6YkH87/services"
          {...Services}
          icon={EventSeatIcon}
        />
        <Resource
          options={{ label: 'Servicios extra' }}
          name="facilities/aeMBRl79T9NdzU6YkH87/extra_services"
          {...ExtraServices}
          icon={AddShoppingCart}
        />
        <Resource
          options={{ label: 'Trabajadores' }}
          name="facilities/aeMBRl79T9NdzU6YkH87/workers"
          {...Workers}
          icon={WorkIcon}
        />
        <Resource
          options={{ label: 'Vacaciones' }}
          name="facilities/aeMBRl79T9NdzU6YkH87/holidays"
          {...Holidays}
          icon={FreeBreakfastIcon}
        />
        <Resource
          options={{ label: 'Instalaciones' }}
          name="facilities/aeMBRl79T9NdzU6YkH87/facility_data"
          {...Facility}
          icon={SettingsApplicationsIcon}
        />
        <Resource
          options={{ label: 'Imágenes' }}
          name="facilities/aeMBRl79T9NdzU6YkH87/images"
          {...Images}
          icon={PermMediaIcon}
        />

      </Admin>
    );
  }
}

export default App;
