import { useEffect, useState } from 'react';
import { getCollection } from '../firebase';

export const  useCollectionPrimitive = (collectionName) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLError, setIsError] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    getCollection(collectionName, null, null)
    .then(function(querySnapshot) {
      let data = [];
      if (querySnapshot.size > 0) {
        querySnapshot.forEach(function(doc) {
          data.push({...doc.data(), id: doc.id});
        });
      }
      setData(data);
      setIsLoading(false);
    }).catch(function(error) {
      console.log("Error getting collection:", error);
      setIsError(true);
      setError(error);
      setData([]);
      setIsLoading(false);
    });


  }, [collectionName]);

  return {isLoading, isLError, data, error};
}