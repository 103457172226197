// in src/posts.js
import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Show,
  Edit,
  Filter,
  BooleanInput,
  required,
  TextField,
  TextInput,
  ShowButton,
  AutocompleteInput,
  DeleteButton,
  BooleanField,
  Create,
  SimpleForm,
  SimpleShowLayout,
  DateField,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
} from "react-admin";

const PostFilter = (props) => (
  <Filter {...props}>
      <TextInput source="title.es" label="Título (Castellano)" />
      <TextInput source="description.es" label="Descripción (Castellano)" />

      <TextInput source="title.en" label="Título (Inglés)" />
      <TextInput source="description.en" label="Descripción (Inglés)" />

      <TextInput source="title.ca" label="Título (Catalán)" />
      <TextInput source="description.ca" label="Descripción (Catalán)" />
  </Filter>
);

const list = (props) => (
  <List {...props} filters={<PostFilter />} title="Listado de campañas" sort={{ field: 'created_at', order: 'DESC' }}>
    <Datagrid>

      <TextField source="title.es" label="Título (Castellano)" />
      <TextField source="description.es" label="Descripción (Castellano)" />

      <TextField source="title.en" label="Título (Inglés)" />
      <TextField source="description.en" label="Descripción (Inglés)" />

      <TextField source="title.ca" label="Título (Catalán)" />
      <TextField source="description.ca" label="Descripción (Catalán)" />

      <DateField source="created_at" label="Fecha de creación" showTime  locales="es-ES" />

      <ShowButton label="" />
    </Datagrid>
  </List>
);

const show = (props) => (
  <Show {...props} title="Ver datos de la campaña" >
    <SimpleShowLayout>

      <TextField source="id" />

      <TextField source="title.es" label="Título (Castellano)" />
      <TextField source="description.es" label="Descripción (Castellano)" />

      <TextField source="title.en" label="Título (Inglés)" />
      <TextField source="description.en" label="Descripción (Inglés)" />

      <TextField source="title.ca" label="Título (Catalán)" />
      <TextField source="description.ca" label="Descripción (Catalán)" />

      <DateField source="created_at" label="Fecha de creación" showTime  locales="es-ES" />
      <DateField source="created_at" label="Fecha de creación" showTime  locales="es-ES"  />

    </SimpleShowLayout>
  </Show>
);

const edit = (props) => (
  <Edit {...props} title="Editar datos de la campaña" undoable={false}>
    <SimpleForm>
      <TextField source="id"/>

      <TextField source="title.es" label="Título (Castellano)" />
      <TextField source="description.es" label="Descripción (Castellano)" />

      <TextField source="title.en" label="Título (Inglés)" />
      <TextField source="description.en" label="Descripción (Inglés)" />

      <TextField source="title.ca" label="Título (Catalán)" />
      <TextField source="description.ca" label="Descripción (Catalán)" />

      <DateField source="created_at" label="Fecha de creación" showTime  locales="es-ES" />
      <DateField source="updated_on" label="última actualización" showTime  locales="es-ES" />

    </SimpleForm>
  </Edit>
);

const create = (props) => (
  <Create {...props} title="Crear una campaña">
    <SimpleForm>
      <TextInput source="title.es" label="Título (Castellano)" validate={[required()]}/>
      <TextInput source="description.es" label="Descripción (Castellano)" validate={[required()]}/>

      <TextInput source="title.en" label="Título (Inglés)" validate={[required()]}/>
      <TextInput source="description.en" label="Descripción (Inglés)" validate={[required()]}/>

      <TextInput source="title.ca" label="Título (Catalán)" validate={[required()]}/>
      <TextInput source="description.ca" label="Descripción (Catalán)" validate={[required()]}/>

    </SimpleForm>
  </Create>
);

export default {
  list: list,
  show: show,
  edit: edit,
  create: create
};
