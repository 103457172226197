// in src/posts.js
import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Show,
  Edit,
  Filter,
  BooleanInput,
  required,
  TextField,
  TextInput,
  ShowButton,
  EditButton,
  DeleteButton,
  BooleanField,
  Create,
  NumberInput,
  SimpleForm,
  NullableBooleanInput,
  SimpleShowLayout,
  DateField,
  ImageInput,
  ImageField,
} from "react-admin";

const Filters = (props) => (
  <Filter {...props}>
    <TextInput source="id" label="ID" />
    <TextInput source="title" label="Título" />
  </Filter>
);

const list = (props) => (
  <List {...props} filters={<Filters />} title="Listado de Imágenes" sort={{ field: 'created_at', order: 'DESC' }}>
    <Datagrid>
      <TextField source="title" label="título" />
      <TextField source="order" label="Orden" />
      <ImageField source="image.src" label="Imagen" />

      <BooleanField source="public" label="Publicado" />

      <DateField source="created_at" label="Fecha de creación" showTime locales="es-ES" />
      <DateField source="updated_on" label="última actualización" showTime locales="es-ES" />

      <ShowButton label="" />
      <EditButton label="" />
    </Datagrid>
  </List>
);

const show = (props) => (
  <Show {...props} title="Ver datos de la imagen" >
    <SimpleShowLayout>

      <TextField source="id" />
      <TextField source="title" label="título" />
      <TextField source="order" label="Orden" />

      <ImageField source="image.src" label="Imagen" />
      <BooleanField source="public" label="Publicado" />

      <DateField source="created_at" label="Fecha de creación" showTime locales="es-ES" />
      <DateField source="updated_on" label="última actualización" showTime locales="es-ES" />

    </SimpleShowLayout>
  </Show>
);

const edit = (props) => (
  <Edit {...props} title="Editar datos de la imagen" undoable={false}>
    <SimpleForm>
      <TextInput source="id" options={{ disabled: true }} />
      <TextInput source="title" label="título" />
      <NumberInput source="order" label="Orden en los listados" validate={[required()]} />

      <ImageInput source="image" label="Imagen" accept="image/*" validate={[required()]} maxSize={2000000}>
        <ImageField source="src" title="title" />
      </ImageInput>

      <BooleanInput source="public" label="Publicado" validate={[required()]} />

      <DateField source="created_at" label="Fecha de creación" showTime locales="es-ES" />
      <DateField source="updated_on" label="última actualización" showTime locales="es-ES" />

    </SimpleForm>
  </Edit>
);

const create = (props) => (
  <Create {...props} title="Crear una imagen">
    <SimpleForm>
      <TextInput source="title" label="título" />
      <NumberInput source="order" label="Orden en los listados" validate={[required()]} />

      <ImageInput source="image" label="Imagen" accept="image/*" validate={[required()]} maxSize={2000000}>
        <ImageField source="src" title="Imagen" />
      </ImageInput>

      <BooleanInput source="public" label="Publicado" validate={[required()]} />

    </SimpleForm>
  </Create>
);

export default {
  list: list,
  show: show,
  edit: edit,
  create: create
};
