// in src/posts.js
import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Show,
  Edit,
  Filter,
  Tab,
  BooleanInput,
  required,
  TextField,
  TextInput,
  ShowButton,
  EditButton,
  SelectInput,
  FormTab,
  TabbedForm,
  UrlField,
  BooleanField,
  TabbedShowLayout,
  DateField,
  NumberInput,
} from "react-admin";

const HourSelector = ({ source, label }) => <SelectInput
  source={source}
  label={label}
  translateChoice={false}
  choices={[
    { id: 'none', name: 'No' },
    { id: '7:00', name: '7:00' },
    { id: '7:30', name: '7:30' },
    { id: '8:00', name: '8:00' },
    { id: '8:30', name: '8:30' },
    { id: '9:00', name: '9:00' },
    { id: '9:30', name: '9:30' },
    { id: '10:00', name: '10:00' },
    { id: '10:30', name: '10:30' },
    { id: '11:00', name: '11:00' },
    { id: '11:30', name: '11:30' },
    { id: '12:00', name: '12:00' },
    { id: '12:30', name: '12:30' },
    { id: '13:00', name: '13:00' },
    { id: '13:30', name: '13:30' },
    { id: '14:00', name: '14:00' },
    { id: '14:30', name: '14:30' },
    { id: '15:00', name: '15:00' },
    { id: '15:30', name: '15:30' },
    { id: '16:00', name: '16:00' },
    { id: '16:30', name: '16:30' },
    { id: '17:00', name: '17:00' },
    { id: '17:30', name: '17:30' },
    { id: '18:00', name: '18:00' },
    { id: '18:30', name: '18:30' },
    { id: '19:00', name: '19:00' },
    { id: '19:30', name: '19:30' },
    { id: '20:00', name: '20:00' },
    { id: '20:30', name: '20:30' },
    { id: '21:00', name: '21:00' },
    { id: '21:30', name: '21:30' },
    { id: '22:00', name: '22:00' },
  ]} />;

const list = (props) => (
  <List {...props} title="Listado de instalaciones">
    <Datagrid>
      <TextField source="name" label="Nombre" />
      <TextField source="description" label="Descripción" />
      <TextField source="address" label="Dirección" />
      <TextField source="phone" label="Teléfono" />

      <DateField source="updated_on" label="última actualización" showTime locales="es-ES" />
      <ShowButton label="" />
      <EditButton label="" />
    </Datagrid>
  </List>
);

const show = (props) => (
  <Show {...props} title="Ver instalación" >
    <TabbedShowLayout>
      <Tab label="Resumen">
        <TextField source="id" />
        <TextField source="name" label="Nombre" />
        <TextField source="description" label="Descripción" />
        <TextField source="phone" label="Teléfono" />

        <DateField source="created_at" label="Fecha de creación" showTime locales="es-ES"  />
        <DateField source="updated_on" label="última actualización" showTime locales="es-ES" />
      </Tab>

      <Tab label="Redes sociales">
        <UrlField source="social_media.facebook" label="Facebook" />
        <UrlField source="social_media.instagram" label="Instagram" />
        <UrlField source="social_media.google" label="Google" />
        <UrlField source="social_media.twitter" label="Twitter" />
      </Tab>

      <Tab label="Dirección">
        <TextField source="address" label="Dirección" />
        <TextField source="coordinate.latitude" label="Coordenadas (latitud)" />
        <TextField source="coordinate.longitude" label="Coordenadas (longitud)" />
      </Tab>

      <Tab label="Reservas">
        <TextField source="bookings.delay" label="Retraso (Minutos)"  />
        <TextField source="bookings.calendar_size_days" label="Duración del calendario (Días)" />
        <TextField source="bookings.maxim_to_schedule" label="Reserevas por usuario" />
      </Tab>

      <Tab label="Horario semanal">
        <TextField source="schedule.day1.start" label="Lunes (Entrada)" />
        <TextField source="schedule.day1.end" label="Lunes (Salida)" />
        <TextField source="schedule.day1.break_time_start" label="Lunes (Comienzo del descanso)" />
        <TextField source="schedule.day1.break_time_end" label="Lunes (Fin del descanso)" />
        <BooleanField source="schedule.day1.closed" label="Lunes (Cerrado)" />
        <hr />
        <TextField source="schedule.day2.start" label="Martes (Entrada)" />
        <TextField source="schedule.day2.end" label="Martes (Salida)" />
        <TextField source="schedule.day2.break_time_start" label="Martes (Comienzo del descanso)" />
        <TextField source="schedule.day2.break_time_end" label="Martes (Fin del descanso)" />
        <BooleanField source="schedule.day2.closed" label="Martes (Cerrado)" />
        <hr />
        <TextField source="schedule.day3.start" label="Miércoles (Entrada)" />
        <TextField source="schedule.day3.end" label="Miércoles (Salida)" />
        <TextField source="schedule.day3.break_time_start" label="Miércoles (Comienzo del descanso)" />
        <TextField source="schedule.day3.break_time_end" label="Miércoles (Fin del descanso)" />
        <BooleanField source="schedule.day3.closed" label="Miércoles (Cerrado)" />
        <hr />
        <TextField source="schedule.day4.start" label="Jueves (Entrada)" />
        <TextField source="schedule.day4.end" label="Jueves (Salida)" />
        <TextField source="schedule.day4.break_time_start" label="Jueves (Comienzo del descanso)" />
        <TextField source="schedule.day4.break_time_end" label="Jueves (Fin del descanso)" />
        <BooleanField source="schedule.day4.closed" label="Jueves (Cerrado)" />
        <hr />
        <TextField source="schedule.day5.start" label="Viernes (Entrada)" />
        <TextField source="schedule.day5.end" label="Viernes (Salida)" />
        <TextField source="schedule.day5.break_time_start" label="Viernes (Comienzo del descanso)" />
        <TextField source="schedule.day5.break_time_end" label="Viernes (Fin del descanso)" />
        <BooleanField source="schedule.day5.closed" label="Viernes (Cerrado)" />
        <hr />
        <TextField source="schedule.day6.start" label="Sábado (Entrada)" />
        <TextField source="schedule.day6.end" label="Sábado (Salida)" />
        <TextField source="schedule.day6.break_time_start" label="Sábado (Comienzo del descanso)" />
        <TextField source="schedule.day6.break_time_end" label="Sábado (Fin del descanso)" />
        <BooleanField source="schedule.day6.closed" label="Sábado (Cerrado)" />
        <hr />
        <TextField source="schedule.day7.start" label="Domingo (Entrada)" />
        <TextField source="schedule.day7.end" label="Domingo (Salida)" />
        <TextField source="schedule.day7.break_time_start" label="Domingo (Comienzo del descanso)" />
        <TextField source="schedule.day7.break_time_end" label="Domingo (Fin del descanso)" />
        <BooleanField source="schedule.day7.closed" label="Domingo (Cerrado)" />
        <hr />
      </Tab>

      <Tab label="Traducciones">
        <TextField source="name_translations.es" label="Nombre (Castellano)" />
        <TextField source="name_translations.en" label="Nombre (Inglés)" />
        <TextField source="name_translations.ca" label="Nombre (Catalán)" />
        <TextField source="name_translations.fr" label="Nombre (Francés)" />
        <hr />
        <TextField source="description_translations.es" label="Descripción (Castellano)" />
        <TextField source="description_translations.en" label="Descripción (Inglés)" />
        <TextField source="description_translations.ca" label="Descripción (Catalán)" />
        <TextField source="description_translations.fr" label="Descripción (Francés)" />
      </Tab>

    </TabbedShowLayout>
  </Show>
);

const edit = (props) => (
  <Edit {...props} title="Editar instalación" undoable={false}>
    <TabbedForm>

      <FormTab label="Resumen">
        <TextInput source="id" options={{ disabled: true }} />
        <TextInput source="name" label="Nombre" validate={[required()]} />
        <TextInput source="description" multiline label="Descripción" validate={[required()]} />
        <TextInput source="phone" label="Teléfono" validate={[required()]} />
        <DateField source="created_at" label="Fecha de creación" showTime locales="es-ES"  />
        <DateField source="updated_on" label="última actualización" showTime locales="es-ES"  />
      </FormTab>

      <FormTab label="Redes sociales">
        <TextInput source="social_media.facebook" label="Facebook" />
        <TextInput source="social_media.instagram" label="Instagram" />
        <TextInput source="social_media.google" label="Google" />
        <TextInput source="social_media.twitter" label="Twitter" />
      </FormTab>

      <FormTab label="Dirección">
        <TextInput source="address" label="Dirección" validate={[required()]} />
        <NumberInput source="coordinate.latitude" label="Coordenadas (latitud)" validate={[required()]} />
        <NumberInput source="coordinate.longitude" label="Coordenadas (longitud)" validate={[required()]} />
      </FormTab>

      <FormTab label="Reservas">
        <NumberInput source="bookings.delay" label="Retraso (Minutos)" validate={[required()]} />
        <NumberInput source="bookings.calendar_size_days" label="Duración del calendario (Días)" validate={[required()]} />
        <NumberInput source="bookings.maxim_to_schedule" label="Reserevas por usuario" validate={[required()]} />
      </FormTab>

      <FormTab label="Horario semanal">
        <HourSelector source="schedule.day1.start" label="Lunes (entrada)" validate={[required()]} />
        <HourSelector source="schedule.day1.end" label="Lunes (Salida)" validate={[required()]}/>
        <HourSelector source="schedule.day1.break_time_start" label="Lunes (Comienzo del descanso)" validate={[required()]} />
        <HourSelector source="schedule.day1.break_time_end" label="Lunes (Fin del descanso)" validate={[required()]} />
        <BooleanInput source="schedule.day1.closed" label="Lunes (Cerrado)" />
        <hr />
        <HourSelector source="schedule.day2.start" label="Martes (entrada)" validate={[required()]}/>
        <HourSelector source="schedule.day2.end" label="Martes (Salida)"validate={[required()]} />
        <HourSelector source="schedule.day2.break_time_start" label="Martes (Comienzo del descanso)" validate={[required()]} />
        <HourSelector source="schedule.day2.break_time_end" label="Martes (Fin del descanso)" validate={[required()]} />
        <BooleanInput source="schedule.day2.closed" label="Martes (Cerrado)" />
        <hr />
        <HourSelector source="schedule.day3.start" label="Miércoles (entrada)" validate={[required()]}/>
        <HourSelector source="schedule.day3.end" label="Miércoles (Salida)" validate={[required()]}/>
        <HourSelector source="schedule.day3.break_time_start" label="Miércoles (Comienzo del descanso)" validate={[required()]} />
        <HourSelector source="schedule.day3.break_time_end" label="Miércoles (Fin del descanso)" validate={[required()]} />
        <BooleanInput source="schedule.day3.closed" label="Miércoles (Cerrado)" />
        <hr />
        <HourSelector source="schedule.day4.start" label="Jueves (entrada)"validate={[required()]} />
        <HourSelector source="schedule.day4.end" label="Jueves (Salida)"validate={[required()]} />
        <HourSelector source="schedule.day4.break_time_start" label="Jueves (Comienzo del descanso)" validate={[required()]} />
        <HourSelector source="schedule.day4.break_time_end" label="Jueves (Fin del descanso)" validate={[required()]} />
        <BooleanInput source="schedule.day4.closed" label="Jueves (Cerrado)" />
        <hr />
        <HourSelector source="schedule.day5.start" label="Viernes (entrada)" validate={[required()]}/>
        <HourSelector source="schedule.day5.end" label="Viernes (Salida)" validate={[required()]}/>
        <HourSelector source="schedule.day5.break_time_start" label="Viernes (Comienzo del descanso)" validate={[required()]} />
        <HourSelector source="schedule.day5.break_time_end" label="Viernes (Fin del descanso)" validate={[required()]} />
        <BooleanInput source="schedule.day5.closed" label="Viernes (Cerrado)" />
        <hr />
        <HourSelector source="schedule.day6.start" label="Sábado (entrada)" validate={[required()]}/>
        <HourSelector source="schedule.day6.end" label="Sábado (Salida)" validate={[required()]}/>
        <HourSelector source="schedule.day6.break_time_start" label="Sábado (Comienzo del descanso)" validate={[required()]} />
        <HourSelector source="schedule.day6.break_time_end" label="Sábado (Fin del descanso)" validate={[required()]} />
        <BooleanInput source="schedule.day6.closed" label="Sábado (Cerrado)" />
        <hr />
        <HourSelector source="schedule.day7.start" label="Domingo (entrada)" validate={[required()]}/>
        <HourSelector source="schedule.day7.end" label="Domingo (Salida)" validate={[required()]}/>
        <HourSelector source="schedule.day7.break_time_start" label="Domingo (Comienzo del descanso)" validate={[required()]} />
        <HourSelector source="schedule.day7.break_time_end" label="Domingo (Fin del descanso)" validate={[required()]} />
        <BooleanInput source="schedule.day7.closed" label="Domingo (Cerrado)" />
        <hr />
      </FormTab>

      <FormTab label="Traducciones">
        <TextInput source="name_translations.es" label="Nombre (Castellano)" />
        <TextInput source="name_translations.en" label="Nombre (Inglés)" />
        <TextInput source="name_translations.ca" label="Nombre (Catalán)" />
        <TextInput source="name_translations.fr" label="Nombre (Francés)" />
        <TextInput source="name_translations.ro" label="Nombre (Rumano)" />
        <hr />
        <TextInput multiline source="description_translations.es" label="Descripción (Castellano)" />
        <TextInput multiline source="description_translations.en" label="Descripción (Inglés)" />
        <TextInput multiline source="description_translations.ca" label="Descripción (Catalán)" />
        <TextInput multiline source="description_translations.fr" label="Descripción (Francés)" />
        <TextInput multiline source="description_translations.ro" label="Descripción (Rumano)" />
      </FormTab>

    </TabbedForm>
  </Edit>
);

export default {
  list: list,
  show: show,
  edit: edit
};
