import { useEffect, useState } from 'react';
import { getDocRef } from '../firebase';

export const useDoc = (collectionName, docId, references = []) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLError, setIsError] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {

    async function getAsyncDoc(collectionName, docId, references) {
      const docRef = getDocRef(collectionName, docId);
      const doc = await docRef.get();
      const docData = { ...doc.data(), id: doc.id };

      references.forEach(async function (reference) {
        const docSelected = docData[reference];
        const docReference = await docSelected.get();
        docData[reference] = { ...docReference.data(), id: docReference.id };
        setData(docData);
        setIsLoading(false);
      });

      if (references.length === 0) {
        setData(docData);
        setIsLoading(false);
      }
    };

    setIsLoading(true);
    try {
      getAsyncDoc(collectionName, docId, references);
      setIsError(false);
      setError(null);
    } catch (error) {
      console.log("Error getting document:", error);
      setIsError(true);
      setError(error);
      setData(null);
      setIsLoading(false);
    }

  }, [collectionName, docId]);

  return { isLoading, isLError, data, error };
}