// in src/Users.js
import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Show,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Edit,
  DateField,
  SimpleShowLayout,
  SimpleForm,
  TextField,
  TextInput,
  ShowButton,
  EditButton,
  BooleanInput,
  BooleanField,
  required,
  EmailField,
  Filter,
} from "react-admin";

const Filters = (props) => (
  <Filter {...props}>
    <TextInput source="id" label="ID" />
    <TextInput source="name" label="Nombre" />
    <TextInput source="surname" label="Apellidos" />
    <TextInput source="phone" label="Teléfono" />
    <TextInput source="email" label="Email" />
  </Filter>
);

export const list = (props) => (
  <List {...props} filters={<Filters />} title="Listado de cliente" sort={{ field: 'created_at', order: 'DESC' }}>
    <Datagrid>
      <TextField source="name" label="Nombre" />
      <TextField source="surname" label="Apellidos" />

      <TextField source="phone" label="Teléfono" />
      <EmailField source="email" label="Email" />
      <BooleanField source="accept_push_notifications" label="Acepta recibir notificaciones" />

      <ReferenceField label="Trabajador" reference="facilities/aeMBRl79T9NdzU6YkH87/workers" source="worker_id">
        <TextField source="name" />
      </ReferenceField>

      <DateField source="created_at" label="Fecha de creación" showTime locales="es-ES" />
      <DateField source="updated_on" label="última actualización" showTime locales="es-ES" />

      <ShowButton label="" />
      <EditButton label="" />
    </Datagrid>
  </List>
);

export const show = (props) => (
  <Show {...props} title="Ver cliente">
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" label="Nombre" />
      <TextField source="surname" label="Apellidos" />

      <TextField source="phone" label="Teléfono" />
      <EmailField source="email" label="Email" />
      <BooleanField source="accept_push_notifications" label="Acepta recibir notificaciones" />

      <ReferenceField label="Trabajador" reference="facilities/aeMBRl79T9NdzU6YkH87/workers" source="worker_id">
        <TextField source="name" />
      </ReferenceField>

      <BooleanField source="requested_deletion" label="El cliente ha solicitado la eliminación de su cuenta" />
      <DateField source="requested_deletion_at" label="Fecha de la solicitud de eliminación" showTime locales="es-ES" />

      <DateField source="deleted_at" label="Fecha la eliminación" showTime locales="es-ES" />

      <DateField source="created_at" label="Fecha de creación" showTime locales="es-ES" />
      <DateField source="updated_on" label="última actualización" showTime locales="es-ES" />
    </SimpleShowLayout>
  </Show>
);

export const edit = (props) => (
  <Edit {...props} title="Editar cliente" undoable={false}>
    <SimpleForm>
      <TextInput source="id" options={{ disabled: true }} />
      <TextInput source="name" label="Nombre" />
      <TextInput source="surname" label="Apellidos" />
      <TextInput source="phone" label="Teléfono" options={{ disabled: true }} />
      <TextInput source="email" label="Email" options={{ disabled: true }} />
      <BooleanInput source="accept_push_notifications" label="Acepta recibir notificaciones" validate={[required()]} />

      <ReferenceInput label="Trabajador" source="worker_id" allowEmpty reference="facilities/aeMBRl79T9NdzU6YkH87/workers">
        <SelectInput optionText="name" />
      </ReferenceInput>

      <BooleanField source="requested_deletion" label="El cliente ha solicitado la eliminación de su cuenta" />
      <DateField source="requested_deletion_at" label="Fecha de la solicitud de eliminación" showTime locales="es-ES" />

      <DateField source="deleted_at" label="Fecha la eliminación" showTime locales="es-ES" />
      <DateField source="created_at" label="Fecha de creación" showTime locales="es-ES" />
      <DateField source="updated_on" label="última actualización" showTime locales="es-ES" />
    </SimpleForm>
  </Edit>
);

export default {
  list: list,
  show: show,
  edit: edit
};