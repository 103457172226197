// in src/posts.js
import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Show,
  Edit,
  Filter,
  Tab,
  required,
  TextField,
  TextInput,
  ShowButton,
  EditButton,
  SelectInput,
  FormTab,
  TabbedForm,
  BooleanField,
  TabbedShowLayout,
  DateField,
  SelectField,
  ReferenceField,
  DateInput,
} from "react-admin";

const userStatusList = [
  { id: 'correct', name: 'Correcto' },
  { id: 'canceled', name: 'Cancelado por el usuario' },
];

const adminStatusList = [
  { id: 'correct', name: 'Correcto' },
  { id: 'canceled', name: 'Cancelado por el usuario' },
  { id: 'canceled_by_admin', name: 'Cancelado por el administrador' },
  { id: 'canceled_by_worker', name: 'Cancelado por el trabajador' },
  { id: 'canceled_by_system', name: 'Cancelado por el sistema' },
];

const StatusSelector = ({ source, label, validate, choices }) => <SelectInput
  source={source}
  label={label}
  translateChoice={false}
  validate={validate}
  choices={choices} />;

const Filters = (props) => (
  <Filter {...props}>
    <TextInput source="id" label="ID" />
    <TextInput label="Nombre" source="name" />
    <TextInput label="Comentario" source="comment" />
    <TextInput label="Teléfono" source="phone" />
    <StatusSelector source="status" choices={userStatusList} label="Estado (usuario)" />
    <StatusSelector source="status_admin" choices={adminStatusList} label="Estado (admin)" />
  </Filter>
);

const list = (props) => (
  <List {...props} filters={<Filters />} title="Listado de reservas" sort={{ field: 'start_datetime', order: 'DESC' }}>
    <Datagrid>
      <TextField source="name" label="Nombre" />
      <TextField source="phone" label="Teléfono" />
      <TextField source="comment" label="Comentario" />
      <SelectField source="status" choices={userStatusList} label="Estado (usuario)" />
      <SelectField source="status_admin" choices={adminStatusList} label="Estado (Admin)" />

      <DateField source="start_datetime" label="Inicio" showTime locales="es-ES" />
      <DateField source="end_with_break_datatime" label="Fin" showTime  locales="es-ES" />

      <DateField source="created_at" label="Fecha de creación" showTime  locales="es-ES" />
      <DateField source="updated_on" label="última actualización" showTime  locales="es-ES" />
      <ShowButton label="" />
      <EditButton label="" />
    </Datagrid>
  </List>
);


const show = (props) => (
  <Show {...props} title="Ver reserva" >
    <TabbedShowLayout>
      <Tab label="Datos de la reserva">
        <TextField source="id" />
        <TextField source="name" label="Nombre" />
        <TextField source="phone" label="Teléfono" />
        <TextField source="comment" label="Comentario" />
        <SelectField source="status" choices={userStatusList} label="Estado (usuario)" />
        <SelectField source="status_admin" choices={adminStatusList} label="Estado (Admin)" />

        <DateField source="start_datetime" label="Inicio" showTime  locales="es-ES" />
        <DateField source="end_with_break_datatime" label="Fin" showTime  locales="es-ES" />

        <TextField source="user_id" label="ID del cliente" />
        <ReferenceField label="Cliente"  source="user_id" reference="users_data">
          <TextField   source="name" />
        </ReferenceField>

        <ReferenceField label="Servicio" source="service_id" reference="facilities/aeMBRl79T9NdzU6YkH87/services">
          <TextField   source="name" />
        </ReferenceField>

        <ReferenceField label="Trabajador" source="worker_id" reference="facilities/aeMBRl79T9NdzU6YkH87/workers">
          <TextField  source="name" />
        </ReferenceField>

        <DateField source="created_at" label="Fecha de creación" showTime locales="es-ES"  />
        <DateField source="updated_on" label="última actualización" showTime locales="es-ES"  />
      </Tab>

      <Tab label="Notificaciones">
        <BooleanField source="notification_sent" label="Notificado" />
        <DateField source="notified_at" label="fecha de la notificación"  locales="es-ES"  showTime />
      </Tab>

    </TabbedShowLayout>
  </Show>
);

const edit = (props) => (
  <Edit {...props} title="Editar reserva" undoable={false}>
    <TabbedForm>
      <FormTab label="Datos de la reserva">
        <TextInput source="id" options={{ disabled: true }} />

        <TextInput source="name" label="Nombre" validate={[required()]} />
        <TextInput source="phone" label="Teléfono" validate={[required()]} />
        <TextInput source="comment" label="Comentario" />
        <SelectField source="status" choices={userStatusList} label="Estado (usuario)" />
        <StatusSelector source="status_admin" choices={adminStatusList} label="Estado (admin)" validate={[required()]} />

        <DateField source="start_datetime" label="Inicio" showTime  locales="es-ES"  />
        <DateField source="end_with_break_datatime" label="Fin" showTime  locales="es-ES"  />

        <TextField source="user_id" label="ID del cliente" />
        <ReferenceField label="Cliente"  source="user_id" reference="users_data">
          <TextField   source="name" />
        </ReferenceField>

        <ReferenceField label="Servicio" source="service_id" reference="facilities/aeMBRl79T9NdzU6YkH87/services">
          <TextField   source="name" />
        </ReferenceField>

        <ReferenceField label="Trabajador" source="worker_id" reference="facilities/aeMBRl79T9NdzU6YkH87/workers">
          <TextField  source="name" />
        </ReferenceField>

        <DateField source="created_at" label="Fecha de creación" showTime  locales="es-ES"  />
        <DateField source="updated_on" label="última actualización" showTime  locales="es-ES"  />
      </FormTab>

      <FormTab label="Notificaciones">
        <BooleanField source="notification_sent" label="Notificado" />
        <DateField source="notified_at" label="fecha de la notificación" showTime  locales="es-ES"  />
      </FormTab>

    </TabbedForm>
  </Edit>
);

export default {
  list: list,
  show: show,
  edit: edit,
};
